import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppService {

  public BaseURl = environment.BaseUrl;

  constructor(public http : HttpClient) { }



  public siteDealer_varifying() {
    return !!localStorage.getItem('usernameBellTell');
  }



  public login = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'signin', obj);

  }

  public signup = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'signup', obj);

  }

  public getEmployee = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getEmployee' , obj);
  }


  public getCategories = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'categoriesapi' , obj);
  }

  public getUsers = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getend_users' , obj);
  }

  public getExperts = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getexperts' , obj);
  }


  public getExpertsMale = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getexpertsmale' , obj);
  }

  public getExpertsFemale = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getexpertsfemale' , obj);
  }


  public getpost = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getpost' , obj);
  }


  public getwallet = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'getwallet' , obj);
  }


  public checkbalance = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'checkbalance' , obj);
  }


  public topupit = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'topupit' , obj);
  }



  public indexApi = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'indexapi' , obj);
  }

  public updateCategoryapi = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'updateCategoryapi' , obj);
  }


  public addAdvertise = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'addAdvertise' , obj);
  }

  public getAdvertise = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'getAdvertise' , obj);
  }



  public deleteAdvertise = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'deleteAdvertise' , obj);
  }

  public updateAdvertise = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'updateAdvertise' , obj);
  }


  public showcost = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'showcost' , obj);
  }

  public updatecost = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'updatecost' , obj);
  }

  public alladme = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'alladme' , obj);
  }

  public superAdminList = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'superAdminList' , obj);
  }
  public superAdminAdd = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'superAdminAdd' , obj);
  }
  public deleteAdminList = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'deleteAdminList' , obj);
  }
  public updateAdminList = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'updateAdminList' , obj);
  }
  
  public dealerUpdateStatus = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'dealerUpdateStatus' , obj);
  }


  public topupdown = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'topupdown' , obj);
  }

  public mastervalue = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'mastervalue' , obj);
  }

  public getDealers = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'getDealers' , obj);
  }

  public companyList = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'companyList' , obj);
  }

  public userDetails  = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'userDetails' , obj);
  }

  public accountDetails = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'accountDetails' , obj);
  }


  public dasboardValues = (obj) : Observable<any[]> =>{
    return this.http.post<any[]>(this.BaseURl + 'dasboardValues' , obj);
  }



}
