import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public injector : Injector) {}

  intercept(req , next) {
    let appService = this.injector.get(AppService);
     let tokenizedReq = req.clone({
       setHeaders : {
         Authorization : `Bearer abc`
       }
     });
     return next.handle(tokenizedReq);
  }
}
