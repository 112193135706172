import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public service: AppService, public router: Router) {}

  canActivate(): boolean {
    if (this.service.siteDealer_varifying()) {
      return true;
    }
    else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
  
}
