import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
constructor(public router : Router){}
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }



  public logout=()=>{
    this.router.navigate(['/login']);
    localStorage.clear();
  }


}
