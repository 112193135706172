import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {

  public notifier: NotifierService;
  public SignUpForm : FormGroup;
  public isToast : boolean = false;
  resStatus: any;
  constructor(public fb : FormBuilder,
    public notifierService: NotifierService,
    public service : AppService, public router : Router) {
      this.notifier = notifierService;
    }



    ngOnInit()
    {
      this.SignUpForm = this.fb.group({
              username : ['' , Validators.required],
              number : ['' , Validators.required],
              password : ['' , Validators.required],
              cPassword  : ['' , Validators.required]
      });
    }



    public signUp = ()=>{

      let x = {
            username  : localStorage.getItem('usernameBellTell'),
            password : localStorage.getItem('passwordBellTell'),
            name : this.SignUpForm.controls['username'].value,
            number : this.SignUpForm.controls['number'].value,
            userpassword : this.SignUpForm.controls['cPassword'].value
      };

      console.log(x);
      this.service.signup(x).subscribe((res : any)=>{
        console.log(res);
        if(res.status === 'true')
        {
          this.SignUpForm.reset();
          this.isToast = true;
          this.notifier.notify("success" , "Successfully Registered!");
        }
        else
        {
          this.resStatus = res.status;
          this.notifier.notify("error" , res.status);
        }
      });
    }


}
