import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit{

  public notifier: NotifierService;
  public SignUpForm : FormGroup;
  public isToast : boolean = false;
  constructor(public fb : FormBuilder,
    public notifierService: NotifierService,
    public service : AppService, public router : Router) {
      this.notifier = notifierService;
    }

ngOnInit()
{
  this.SignUpForm = this.fb.group({
          username : ['' , Validators.required],
          number : ['' , Validators.required],
          password : ['' , Validators.required],
          cPassword  : ['' , Validators.required]
  });
}



public signUp = ()=>{
  let x = {
        name : this.SignUpForm.controls['username'].value,
        number : this.SignUpForm.controls['number'].value,
        password : this.SignUpForm.controls['cPassword'].value
  };

  console.log(x);
  this.service.signup(x).subscribe((res : any)=>{
    console.log(res);
    if(res.status === 'true')
    {
      this.SignUpForm.reset();
      this.isToast = true;
      this.notifier.notify("success" , "Successfully Registered!");
    }
    else
    {
      this.notifier.notify("error" , "Something Went Wrong!");
    }
  });
}



}
